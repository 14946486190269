import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Download GPX tracks`}</h4>
    <p>{`Open the activitypage (by clicking on a track on the map and then again on
the inforbar, or clicking once on a track in the list)`}</p>
    <br />
    <p>{`Below the name of the activity you see the blue action buttons. Click on
"Download".`}</p>
    <br />
    <p>{`The GPX file is saved on your phones local storage. Usually it is something
like the "Download" folder.`}</p>
    <br />
    <p>{`Go to this folder and click on the file. Your phone will suggest apps to open
it. Select the app of your GPS device to transfer the track to your device.
Or open the file in another app with offline maps and navigation.`}</p>
    <Image src="features/activitypagetop2.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/navigation" mdxType="Link">
  ← Show location
    </Link>
    <Link className="float-right" to="/features/search" mdxType="Link">
  Search →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      